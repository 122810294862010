'use client';

import { useEffect, useState } from 'react';
import { SwiperItem, SwiperProps } from './type';
import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import Linker from '@app/components/linker/linker';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import { Button, CTAItem, Left, Right, slugify, StaticImage } from 'mmds';
import s from './Swiper.module.css';
import { isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect';
import cn from 'clsx';

const SwiperCapsuleVariant = ({ block, context }: SwiperProps) => {
    const { context: contextText, items } = block;
    const itemsCheckForArrowVisibility = items.length > 3;
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const hash = window.location.hash;

            if (hash === '#capsule') {
                const handleImageLoad = () => {
                    const allImages =
                        document.querySelectorAll<HTMLImageElement>(
                            '.swiper-capsule-variant-image'
                        );

                    const loadedImages = Array.from(allImages).every(
                        (img) => img.complete
                    );

                    if (loadedImages) {
                        setImagesLoaded(true);
                    }
                };

                // Attach load event listeners to images
                const images = document.querySelectorAll<HTMLImageElement>(
                    '.swiper-capsule-variant-image'
                );
                images.forEach((img) => {
                    if (img.complete) {
                        handleImageLoad();
                    } else {
                        img.addEventListener('load', handleImageLoad);
                    }
                });

                // Clean up event listeners on unmount
                return () => {
                    images.forEach((img) => {
                        img.removeEventListener('load', handleImageLoad);
                    });
                };
            }
        }
    }, []);

    useEffect(() => {
        if (imagesLoaded && window.location.hash === '#capsule') {
            const capsules = document.querySelectorAll(
                '.swiper-capsule-variant'
            );

            // Scroll to the first capsule element
            if (capsules.length > 0 && (isChrome || isEdge)) {
                capsules[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            } else if (capsules.length > 0 && (isFirefox || isSafari)) {
                capsules[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }, [imagesLoaded]);

    return (
        <div className={cn(s.swiperRoot, 'swiper-capsule-variant')}>
            <div className={s.contextContainer}>
                <div className={s.context}>{contextText}</div>
                {itemsCheckForArrowVisibility && (
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-4">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-4">
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                className="swiper4"
                swiperIconNextClassName="swiper-icon-next-4"
                swiperIconPrevClassName="swiper-icon-prev-4"
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                slidePerViewInMobile={1.2}
                slidePerViewInDesktop={3.1}
            >
                {items.map((item: SwiperItem, index) => {
                    const { desktopImage, mobileImage, CTASet } = item;
                    const identifier = `swiper_capsule_variant_${slugify(
                        contextText
                    )}_${index}`;
                    const passthrough = !CTASet?.[0]?.link;
                    const link = CTASet?.[0]?.link;
                    const extractStoriesTitleFromLink = link
                        ?.split('/stories/')[1]
                        ?.split('-')
                        ?.join(' ');

                    return (
                        <SwiperSlide key={index} data-testid={identifier}>
                            <Linker
                                passthrough={passthrough}
                                href={link || null}
                                className={s.linkableWrapper}
                                title={`Go to the page ${
                                    extractStoriesTitleFromLink || contextText
                                }`}
                                lang={context.lang}
                            >
                                <div className={s.overlayOnImage} />
                                <StaticImage
                                    desktopImage={desktopImage}
                                    mobileImage={mobileImage}
                                    fullWidth={true}
                                    urlTransformer={proxyImageUrlIfEnabled}
                                    classname={'swiper-capsule-variant-image'}
                                />
                            </Linker>
                            <div className={s.capsuleButton}>
                                {CTASet?.map((item: CTAItem, index) => {
                                    return (
                                        item.link && (
                                            <Button
                                                variant={item.variant}
                                                label={item.label}
                                                icon={item.icon}
                                                hrefLink={item.link}
                                                key={index}
                                                lang={context.lang}
                                            />
                                        )
                                    );
                                })}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperCapsuleVariant;
