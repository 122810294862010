'use client';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    Value as E164Number,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fetch from 'isomorphic-unfetch';
import { useIsomorphicLayoutEffect } from '@lib/useIsomorphicLayoutEffect';
import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';
import { useParams, useSearchParams } from 'next/navigation';
import { Button, Checkbox, Input, Select } from 'mmds';
import s from './challenge-or-event-form.module.css';
import cn from 'clsx';

const ChallengeOrEventForm = ({ formAttributes, formItems }) => {
    const { lang } = useParams();
    const searchParams = useSearchParams();
    const isAnchorToForm = searchParams.get('anchorToForm') === 'true';
    const eventRegionsArr = formItems;

    const [complete, setComplete] = useState(false);
    const [redeemed, setRedeemed] = useState(false);
    const [signedUp, setSignedUp] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [discountCode, setDiscountCode] = useState();
    const [message, setMessage] = useState<string | null>(null);
    const [agree, setAgree] = useState(false);
    const [smsAgree, setSMSAgree] = useState(false);
    const [declare, setDeclare] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [textArea, setTextArea] = useState('');
    const [instagramHandle, setInstagramHandle] = useState('');
    const [stravaProfileHandle, setStravaProfileHandle] = useState('');
    const [region, setRegion] = useState('');
    const [gender, setGender] = useState('');

    const [phoneInput, setPhoneInput] = useState('');
    const [votingInput, setVotingInput] = useState('');
    const [geoLocationData, setGeoLocationData] = useState<any>(null);

    useIsomorphicLayoutEffect(() => {
        // declare the data fetching function
        const fetchGeoLocation = async () => {
            const response = await fetch(`/api/geo-locate`);
            const geoData = await response.json();

            setGeoLocationData(geoData);
        };

        fetchGeoLocation().catch(console.error);
    }, []);

    let countryCode = 'AU';
    if (geoLocationData) countryCode = geoLocationData.countryCode;

    const {
        form_type,
        region_selector_placeholder,
        klaviyo_property_key,
        discount_code_prefix,
        discount_pricing_rule_id_eu,
        discount_pricing_rule_id_intl,
        discount_pricing_rule_id_retail,
        discount_pricing_rule_id_uk,
        discount_pricing_rule_id_us,
        show_mobile_number_input,
        show_gender_selection,
        show_email_opt_in,
        show_privacy_policy,
        show_event_region_selection,
        email_opt_in_copy_text,
        sign_up_button_copy_text,
        privacy_policy_copy_text,
        privacy_policy_url,
        terms_and_conditions_url,
        profile_tag,
        eu_discount_rule_control,
        intl_discount_rule_control,
        retail_discount_rule_control,
        uk_discount_rule_control,
        us_discount_rule_control,
        show_sms_opt_in,
        sms_opt_in_copy_text,
        event_form_complete_message,
        show_voting_options,
        voting_options,
        region_option_field_placeholder,
        voting_option_field_placeholder,
        challenge_form_success_message_head,
        intl_challenge_form_success_msg_pt1,
        us_challenge_form_success_msg_pt1,
        uk_challenge_form_success_msg_pt1,
        eu_challenge_form_success_msg_pt1,
        intl_challenge_form_success_msg_pt2,
        us_challenge_form_success_msg_pt2,
        uk_challenge_form_success_msg_pt2,
        eu_challenge_form_success_msg_pt2,
        display_free_text_input_field,
        free_text_input_field_title,
        display_instagram_handle_input_field,
        display_strava_profile_input_field,
    } = formAttributes;

    const onSubmit = async (e) => {
        // Prevent scrolling to top of form etc.
        e.preventDefault();
        // debugger;
        if (!show_privacy_policy) {
            setDeclare(true);
        }

        // if event region selection is hidden, assign intl to region.
        if (!show_event_region_selection && !region) {
            setRegion('intl');
        }

        if (message) setMessage(null);

        // require email, first name, last name
        if (!emailAddress || !firstName || !lastName) {
            setMessage('Please enter your contact details');
        } else if (
            // require email, first name, last name and Gender
            !emailAddress ||
            !firstName ||
            !lastName ||
            (show_gender_selection && !gender)
        ) {
            setMessage('Please enter your contact details and Gender');
        } else if (
            // require email, first name, last name and Region
            !emailAddress ||
            !firstName ||
            !lastName ||
            (show_event_region_selection && !region)
        ) {
            setMessage('Please enter your contact details and Region');
        } else if (
            // require email, first name, last name, Gender and Region
            !emailAddress ||
            !firstName ||
            !lastName ||
            (show_event_region_selection && !region) ||
            (show_gender_selection && !gender)
        ) {
            setMessage('Please enter your contact details, Gender and Region');
        } else if (
            // require email, first name, last name, text area
            !emailAddress ||
            !firstName ||
            !lastName ||
            (free_text_input_field_title && textArea.trim().length === 0)
        ) {
            setMessage('Please enter your contact details and message');
        } else if (!declare && show_privacy_policy === true) {
            setMessage('Please accept our terms & conditions');
        } else if (form_type.toLowerCase() === 'challenge' && !agree) {
            setMessage('Please accept email opt-in');
        } else if (emailAddress.indexOf('+') !== -1) {
            setMessage(
                'Please enter a valid email address with no + character'
            );
        } else if (
            show_mobile_number_input &&
            phoneInput &&
            (!isPossiblePhoneNumber(phoneInput) ||
                !isValidPhoneNumber(phoneInput))
        ) {
            setMessage('Please enter a valid mobile phone number');
        } else {
            setSubmitting(true);

            const now = Date.now();
            if (form_type.toLowerCase() === 'challenge') {
                const body = {
                    email: emailAddress,
                    first_name: firstName,
                    last_name: lastName,
                    mobile_phone: phoneInput,
                    region,
                    gender,
                    email_promo: agree,
                    sms_promo: smsAgree,
                    declare,
                    tag: profile_tag,
                    discountCodePrefix: discount_code_prefix,
                    discountRuleIdEu: discount_pricing_rule_id_eu,
                    discountRuleIdIntl: discount_pricing_rule_id_intl,
                    discountRuleIdRetail: discount_pricing_rule_id_retail,
                    discountRuleIdUk: discount_pricing_rule_id_uk,
                    discountRuleIdUs: discount_pricing_rule_id_us,
                    eu_discount_rule_control: eu_discount_rule_control,
                    intl_discount_rule_control: intl_discount_rule_control,
                    us_discount_rule_control: us_discount_rule_control,
                    uk_discount_rule_control: uk_discount_rule_control,
                    retail_discount_rule_control: retail_discount_rule_control,
                    form_type: form_type,
                    klaviyo_property_key: klaviyo_property_key,
                    lang,
                };
                const route = '/api/services/challenge-complete';
                const signature = await hmacSignature(
                    process.env.NEXT_PUBLIC_API_SECRET,
                    now,
                    route,
                    body
                );
                const response = await fetch(route, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-authentication': `${now}:${signature}`,
                    },
                    body: JSON.stringify(body),
                });

                const responseObject = await response.json();

                const status = responseObject?.status;

                if (status === 'redeemed') {
                    setRedeemed(true);
                }

                const code = responseObject?.discountCode;
                const message = responseObject?.message;
                const omneoErrorMessage = responseObject?.omneoErrorMessage;

                setSubmitting(false);
                if (code) {
                    setDiscountCode(code);
                    setComplete(true);
                } else if (message) {
                    setMessage(message);
                } else if (omneoErrorMessage) {
                    setMessage(omneoErrorMessage);
                } else {
                    setMessage(
                        'Can not redeem your reward. Please check your input and try again later.'
                    );
                }
            }

            if (form_type.toLowerCase() === 'event') {
                const body = {
                    email: emailAddress,
                    first_name: firstName,
                    last_name: lastName,
                    mobile_phone: phoneInput,
                    region: region,
                    gender: gender,
                    email_promo: agree,
                    sms_promo: smsAgree,
                    declare: declare,
                    tag: profile_tag,
                    event_form_complete_message: event_form_complete_message,
                    voting_option: votingInput,

                    textArea_title: free_text_input_field_title,
                    textArea_input: textArea,
                    instagram_handle: instagramHandle,
                    strava_profile_handle: stravaProfileHandle,
                    lang,
                };
                const route = '/api/services/event-signup';

                const signature = await hmacSignature(
                    process.env.NEXT_PUBLIC_API_SECRET,
                    now,
                    route,
                    body
                );
                const response = await fetch(route, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-authentication': `${now}:${signature}`,
                    },
                    body: JSON.stringify(body),
                });

                const responseObject = await response.json();

                const status = responseObject?.status;
                const omneoErrorMessage = responseObject?.omneoErrorMessage;

                if (status === 'signedup') {
                    setSignedUp(true);
                }

                const message = responseObject?.message;

                setSubmitting(false);

                if (message) {
                    setMessage(message);
                    setComplete(true);
                } else if (omneoErrorMessage) {
                    setMessage(omneoErrorMessage);
                } else {
                    setMessage('Please check your input and try again later.');
                }
            }
        }
    };

    const getPlaceholderValues = (region) => {
        switch (region.toUpperCase()) {
            case 'US':
                return {
                    message_part_one: us_challenge_form_success_msg_pt1,
                    message_part_two: us_challenge_form_success_msg_pt2,
                };
            case 'EU':
                return {
                    message_part_one: eu_challenge_form_success_msg_pt1,
                    message_part_two: eu_challenge_form_success_msg_pt2,
                };
            case 'UK':
                return {
                    message_part_one: uk_challenge_form_success_msg_pt1,
                    message_part_two: uk_challenge_form_success_msg_pt2,
                };
            case 'INTL':
            default:
                return {
                    message_part_one: intl_challenge_form_success_msg_pt1,
                    message_part_two: intl_challenge_form_success_msg_pt2,
                };
        }
    };

    const { message_part_one, message_part_two } = getPlaceholderValues(region);

    // anchor to form
    useEffect(() => {
        if (isAnchorToForm) {
            const scrollIntoViewWithOffset = (selector, offset) => {
                const element = document.getElementById(selector);
                if (element) {
                    const y =
                        element.getBoundingClientRect().top +
                        window.pageYOffset +
                        offset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            };
            scrollIntoViewWithOffset('form', -120);
        }
    }, []);

    const regionSelectOptions = eventRegionsArr.map(
        ({ event_location, event_region }) => ({
            label: event_location,
            value: event_region,
        })
    );

    const genderSelectOptions = [
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
        { label: 'Non-binary', value: 'other' },
        { label: 'Prefer Not To Say', value: 'withheld' },
    ];

    const challengeRegionSelectOptions = [
        { label: 'USA/USD', value: 'US' },
        { label: 'EU/EUR', value: 'EU' },
        { label: 'UK/GBP', value: 'UK' },
        { label: 'REST OF WORLD/AUD', value: 'INTL' },
    ];

    let votingOptions = [];
    if (voting_options != null) {
        const options = voting_options.split('|');
        votingOptions = options.map((option, index) => {
            const value = option.replace(/ /g, '-');

            return {
                label: option,
                value: value,
            };
        });
    }

    if (redeemed) {
        return (
            <section className={cn(s.eventMessageZone, s.section)}>
                <p className="mmds-component-one-detail">{message}</p>
            </section>
        );
    }

    // if already signed up
    if (signedUp) {
        return (
            <section className={cn(s.eventMessageZone, s.section)}>
                <p className="mmds-component-one-detail">{message}</p>
            </section>
        );
    }

    let privacyCopyText: any =
        'I declare that I am over the age of 18 and agree to the MAAP rules and Terms & Conditions.';

    if (
        privacy_policy_copy_text?.[0] &&
        typeof privacy_policy_copy_text?.[0] === 'object' &&
        privacy_policy_copy_text?.[0]?.text
    ) {
        privacyCopyText = privacy_policy_copy_text?.[0]?.text;
    }

    // Not 100% sure if this is in privacy_policy_copy_text or privacy_policy_copy_text[0], couldn't find a
    // instance to test

    if (
        privacy_policy_copy_text &&
        typeof privacy_policy_copy_text === 'string'
    ) {
        privacyCopyText = privacy_policy_copy_text;
    }

    return (
        <>
            {complete ? (
                form_type.toLowerCase() === 'challenge' ? (
                    <div
                        className={cn(
                            s.column,
                            s.challengeMessageZone,
                            s.section
                        )}
                    >
                        <h2
                            data-testid="challenge_success_heading"
                            className={cn(
                                'mmds-subtitle-two',
                                s.messageHeading
                            )}
                        >
                            {challenge_form_success_message_head}
                        </h2>
                        <div
                            data-testid="challenge_message_part_one"
                            className={cn(
                                'mmds-component-one-detail',
                                s.messageSection
                            )}
                        >
                            {!!message_part_one && message_part_one}
                        </div>
                        <p
                            data-testid="discount_code"
                            className={cn(
                                'mmds-component-copy-one',
                                s.messageSection
                            )}
                        >
                            {discountCode}
                        </p>
                        <div
                            data-testid="challenge_message_part_two"
                            className={cn(
                                'mmds-component-one-detail',
                                s.messageSection
                            )}
                        >
                            {!!message_part_two && message_part_two}
                        </div>
                    </div>
                ) : (
                    <section className={cn(s.eventMessageZone, s.section)}>
                        <h2>{message}</h2>
                    </section>
                )
            ) : (
                <section className={s.section}>
                    <form
                        id="form"
                        className={cn(s.form, s.stack)}
                        data-flow-space="large"
                    >
                        {/* voting selection */}
                        {form_type.toLowerCase() === 'event' &&
                            votingOptions != null &&
                            show_voting_options == true && (
                                <Select
                                    label={
                                        voting_option_field_placeholder
                                            ? voting_option_field_placeholder
                                            : 'Select voting option'
                                    }
                                    options={votingOptions}
                                    value={votingInput}
                                    onChange={(e) =>
                                        setVotingInput(e.target.value)
                                    }
                                    id="voting"
                                    name="voting"
                                    data-testid="voting_input"
                                    placeholder="Select voting option"
                                />
                            )}
                        <div className={s.stack}>
                            {/* First Name Input */}
                            <Input
                                labelText="First name"
                                type="text"
                                id="first name"
                                name="first name"
                                data-testid="firstname_input"
                                onChange={(value) => setFirstName(value)}
                                required
                            />
                            {/* Last Name Input */}
                            <Input
                                labelText="Last name"
                                type="text"
                                id="last name"
                                name="last name"
                                data-testid="lastname_input"
                                onChange={(value) => setLastName(value)}
                                required
                            />
                            <Input
                                labelText="Email"
                                type="email"
                                id="email"
                                name="email"
                                data-testid="email_input"
                                onChange={(value) => setEmailAddress(value)}
                                required
                            />

                            {show_mobile_number_input && (
                                <div className={''}>
                                    <label className="mmds-component-one-detail">
                                        Mobile
                                    </label>
                                    {/* React phone input package types giving me grief, so escape hatch */}
                                    <PhoneInput
                                        value={phoneInput as E164Number}
                                        onChange={setPhoneInput as any}
                                        international
                                        defaultCountry={countryCode as any}
                                        data-testid="phone_input"
                                        className={cn(
                                            s.phoneInput,
                                            'mmds-component-one'
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        {form_type.toLowerCase() === 'challenge' && (
                            <Select
                                label={`${
                                    region_selector_placeholder
                                        ? region_selector_placeholder
                                        : 'Preferred shopping region'
                                }`}
                                options={challengeRegionSelectOptions}
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                                id="region"
                                name="region"
                                data-testid="region_input"
                                placeholder="Preferred Shopping Region"
                                width={'100%'}
                            />
                        )}
                        {form_type.toLowerCase() === 'event' &&
                            show_event_region_selection && (
                                <Select
                                    options={challengeRegionSelectOptions}
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                    id="region"
                                    name="region"
                                    placeholder="Preferred Shipping Region"
                                    width={'100%'}
                                />
                            )}
                        {/* gender selection */}
                        <Select
                            label="Gender"
                            options={genderSelectOptions}
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            id="gender"
                            name="gender"
                            width={'100%'}
                            placeholder="Gender"
                        />
                        {/* Instagram input */}
                        {display_instagram_handle_input_field && (
                            <Input
                                labelText="Instagram"
                                type="text"
                                id="instagramInput"
                                name="instagramInput"
                                data-testid="instagram_handle_input"
                                onChange={(value) => setInstagramHandle(value)}
                                required
                            />
                        )}
                        {/* Strava Profile input */}
                        {display_strava_profile_input_field && (
                            <Input
                                labelText="Strava"
                                type="text"
                                id="stravaProfileInput"
                                name="straveProfileInput"
                                data-testid="strava_profile_input"
                                onChange={(value) =>
                                    setStravaProfileHandle(value)
                                }
                                required
                            />
                        )}

                        {/* textarea message field */}
                        {display_free_text_input_field && (
                            <div>
                                <label className="mmds-component-one-detail">
                                    {free_text_input_field_title}
                                </label>
                                <textarea
                                    rows={7}
                                    id="challenge_textarea"
                                    data-testid="challenge_textarea"
                                    className={cn(
                                        s.textArea,
                                        'mmds-component-one'
                                    )}
                                    onChange={(e) =>
                                        setTextArea(e.target.value)
                                    }
                                    required
                                    maxLength={200}
                                />
                            </div>
                        )}
                        <div
                            className={cn(s.stack, s.consent)}
                            data-flow-space="small"
                        >
                            {/* <div className={'mmds-component-one'}>
                                <span>*Required fields</span>
                            </div> */}
                            {/* Email Opt-In */}
                            <Checkbox
                                label={
                                    email_opt_in_copy_text
                                        ? email_opt_in_copy_text
                                        : 'I agree to be emailed my voucher and subscribe to MAAP’s newsletter, where I’ll be the first to know about new products, group rides and all things MAAP.'
                                }
                                id="newsletter_consent_input"
                                data-testid="newsletter_consent_input"
                                required={
                                    form_type.toLowerCase() === 'challenge'
                                }
                                checked={agree}
                                onCheckedChange={() => setAgree(!agree)}
                            />

                            {/* SMS opt-in */}
                            {show_sms_opt_in && (
                                <Checkbox
                                    label={
                                        sms_opt_in_copy_text
                                            ? sms_opt_in_copy_text
                                            : ' I agree to receive SMS updates.'
                                    }
                                    id="sms_consent_input"
                                    data-testid="sms_consent_input"
                                    checked={smsAgree}
                                    onCheckedChange={() =>
                                        setSMSAgree(!smsAgree)
                                    }
                                />
                            )}

                            {/* privacy policy */}
                            {show_privacy_policy === true && (
                                <Checkbox
                                    label={privacyCopyText}
                                    id="terms_consent_input"
                                    data-testid="terms_consent_input"
                                    required
                                    checked={declare}
                                    onCheckedChange={() => setDeclare(!declare)}
                                />
                            )}
                        </div>
                        <Button
                            disabled={submitting}
                            data-testid="complete_challenge_button"
                            type="submit"
                            onClick={(e) => onSubmit(e)}
                            fullWidth
                        >
                            {submitting ? (
                                <span>Submitting...</span>
                            ) : sign_up_button_copy_text ? (
                                sign_up_button_copy_text
                            ) : (
                                'Redeem Reward'
                            )}
                        </Button>
                        {show_privacy_policy === true && (
                            <div className={cn(s.terms, 'mmds-copy-three')}>
                                By entering your details you agree to MAAP&#39;s{' '}
                                <Link
                                    href={
                                        terms_and_conditions_url
                                            ? terms_and_conditions_url
                                            : 'https://maap.cc/pages/terms-and-conditions'
                                    }
                                    legacyBehavior
                                >
                                    <a>Terms & Conditions</a>
                                </Link>{' '}
                                and{' '}
                                <Link
                                    href={
                                        privacy_policy_url
                                            ? privacy_policy_url
                                            : 'https://maap.cc/pages/privacy-policy'
                                    }
                                    legacyBehavior
                                >
                                    <a>Privacy Policy</a>
                                </Link>
                            </div>
                        )}
                        {message && (
                            <div
                                className={s.validationMessage}
                                data-testid="validation_message"
                            >
                                {message}
                            </div>
                        )}
                    </form>
                </section>
            )}
        </>
    );
};

export default ChallengeOrEventForm;
