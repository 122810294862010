'use client';

import React, { useState } from 'react';
import s from '@app/components/swiper/Swiper.module.css';
import ScrollBarSwiper from '@app/components/scrollbar-swiper';
import { Button, CTAItem, Left, Right, slugify, StaticImage } from 'mmds';
import { SwiperSlide } from 'swiper/react';
import Linker from '@app/components/linker/linker';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import { SwiperItem, SwiperProps } from '@app/components/swiper/type';
import { generateTheme } from '@app/lib/helpers/generate-theme';

const SwiperTabbedCardVariant = ({ block, context }: SwiperProps) => {
    const {
        items,
        context: contextText,
        defaultTab,
        mobileTheme,
        desktopTheme,
    } = block;

    const [selectedTab, setSelectedTab] = useState(defaultTab);
    if (!selectedTab) return null;

    const tabNames: any[] = [
        ...new Set(
            items.map((item: SwiperItem) => {
                return item.tabName;
            })
        ),
    ];

    const filteredTabItems = tabNames
        .map((tabName) => {
            return items
                .filter((item) => item.tabName === tabName)
                .map(({ desktopImage, mobileImage, CTASet, tabName }) => ({
                    tabName: tabName,
                    items: {
                        desktopImage,
                        mobileImage,
                        CTASet,
                    },
                }));
        })
        .flat();

    const getTabContent = (tabName: string): any[] => {
        return filteredTabItems.filter((item) => item.tabName === tabName);
    };

    const activeTabItem = getTabContent(selectedTab);

    const theme = generateTheme({
        mobileTheme,
        desktopTheme,
    });

    return (
        <div className={s.swiperRoot} data-theme={theme}>
            <div className={s.contextContainer}>
                <div className={s.context}>
                    {contextText}
                    <span className={s.divider}>:</span>
                    <div className={s.tabbedCardTabSelector}>
                        {tabNames.map((tabName, key) => {
                            return (
                                <Button
                                    key={key}
                                    variant={
                                        selectedTab === tabName
                                            ? 'text'
                                            : 'quite'
                                    }
                                    onClick={() => setSelectedTab(tabName)}
                                    textVariantPadding={false}
                                    className={s.tabbedCardTabButtons}
                                >
                                    {tabName}
                                </Button>
                            );
                        })}
                    </div>
                </div>
                {activeTabItem.length > 3 && (
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-tabbed_card">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-tabbed_card">
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                className="swiperTabbedCard"
                swiperIconNextClassName="swiper-icon-next-tabbed_card"
                swiperIconPrevClassName="swiper-icon-prev-tabbed_card"
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                slidePerViewInMobile={1.2}
                slidePerViewInDesktop={3.1}
            >
                {activeTabItem.map((item, index) => {
                    const { desktopImage, mobileImage, CTASet } = item.items;
                    const identifier = `swiper_tabbed_card_variant_${slugify(
                        item.tabName
                    )}_${index}`;
                    const passthroughlink = !CTASet?.[0]?.link;
                    const link = CTASet?.[0]?.link;
                    const extractTitleFromLink = link
                        ?.replace(
                            /\/(collections|stories|products|pages)\//,
                            ''
                        )
                        ?.replace(/-/g, ' ');

                    return (
                        <SwiperSlide key={index} data-testid={identifier}>
                            <Linker
                                passthrough={passthroughlink}
                                href={link || null}
                                className={s.linkableWrapper}
                                title={`Go to the page ${
                                    extractTitleFromLink || item.tabName
                                }`}
                                lang={context.lang}
                            >
                                <div className={s.overlayOnImage} />
                                <StaticImage
                                    desktopImage={desktopImage}
                                    mobileImage={mobileImage}
                                    fullWidth={true}
                                    urlTransformer={proxyImageUrlIfEnabled}
                                />
                            </Linker>
                            <div className={s.capsuleButton}>
                                {CTASet?.map((item: CTAItem, index: number) => {
                                    return (
                                        item.link && (
                                            <Button
                                                variant={item.variant}
                                                label={item.label}
                                                icon={item.icon}
                                                hrefLink={item.link}
                                                key={index}
                                                lang={context.lang}
                                            />
                                        )
                                    );
                                })}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperTabbedCardVariant;
