'use client';

import React, { useState } from 'react';
import { PosterItem } from './types';
import s from './PosterFeatureStack.module.css';
import { PatternCTASet, StaticImage } from 'mmds';
import { generateTheme } from '@app/lib/helpers/generate-theme';
import { useParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';

type PosterItemProps = {
    item: PosterItem;
};

const PosterFeatureStackVariantItem = ({ item }: PosterItemProps) => {
    const { featureStack } = item;
    const { lang } = useParams();
    const [defaultFeature, setDefaultFeature] = useState(featureStack?.[0]);

    const theme = generateTheme({
        mobileTheme: defaultFeature?.mobileTheme,
        desktopTheme: defaultFeature?.desktopTheme,
    });

    return (
        <div
            className={s.featureStackRoot}
            data-theme={theme}
            key={`poster_feature_stack_${defaultFeature?.productTitle}`}
        >
            <div
                className={s.featureStackBG}
                style={{
                    backgroundColor:
                        defaultFeature?.backgroundColor || '#E7E7E7',
                }}
            >
                <div className={s.productTitle}>
                    {defaultFeature?.productTitle}
                </div>
                <div>
                    <div className={s.thumbnail}>
                        {featureStack?.map((item, index) => {
                            return (
                                <div
                                    key={`${item.backgroundColor}_${index}`}
                                    onClick={() => setDefaultFeature(item)}
                                >
                                    <StaticImage
                                        desktopImage={item.productThumbnail}
                                        desktopWidth={'64'}
                                        desktopHeight={'84'}
                                        mobileImage={item.productThumbnail}
                                        mobileWidth={'48'}
                                        mobileHeight={'64'}
                                        altDescription={
                                            defaultFeature?.productTitle
                                        }
                                        urlTransformer={proxyImageUrlIfEnabled}
                                    />
                                    {defaultFeature?.productThumbnail ===
                                        item?.productThumbnail && (
                                        <div className={s.thumbnailSelected} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {defaultFeature?.description && (
                        <div className={s.content}>
                            {defaultFeature?.description}
                        </div>
                    )}
                    {defaultFeature?.CTASet?.[0]?.label && (
                        <div className={s.button}>
                            <PatternCTASet
                                set={defaultFeature.CTASet}
                                lang={lang as Language}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={s.featureStackImage}>
                <StaticImage
                    desktopImage={defaultFeature?.productImage.desktopImage}
                    mobileImage={defaultFeature?.productImage.mobileImage}
                    altDescription={defaultFeature?.productTitle}
                    fullWidth={true}
                    urlTransformer={proxyImageUrlIfEnabled}
                />
                <div className={s.productTitleForImage}>
                    {defaultFeature?.productTitle}
                </div>
            </div>
        </div>
    );
};

export default PosterFeatureStackVariantItem;
