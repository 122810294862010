'use client';

import React from 'react';
import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Left, ProductCard, Right, slugify } from 'mmds';
import { ProductConnectionWithFilters } from '@app/lib/shopify/types/Collection';
import connectShopify from '@app/components/product-card/connect-shopify';
import { trimGID } from '@app/lib/shopify/helpers/gid';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import s from './Swiper.module.css';

type SwiperProductShopifyVariantProps = {
    products: ProductConnectionWithFilters;
    context: string;
    customRootClass?: string;
    instanceID?: string;
    clientSettings: ShopifyClientContext;
};

function imageGetter(imageSRCs: string[]): (string | undefined)[] {
    const mainImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_CLP_PREC_Lifestyle_01')
    );
    const mainImageFallback = imageSRCs.find((url: string | string[]) =>
        url.includes('_LP_FLATLAY')
    );

    const hoverImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_CLP_PREC_Lifestyle_02')
    );
    const fallbackHoverImage = imageSRCs.find((url: string | string[]) =>
        url.includes('PRODUCT_CARD_ALT')
    );

    return [mainImage || mainImageFallback, hoverImage || fallbackHoverImage];
}

const SwiperProductShopifyVariant = ({
    products,
    context,
    customRootClass,
    instanceID,
    clientSettings,
}: SwiperProductShopifyVariantProps) => {
    const { edges } = products;
    if (!edges) return null;
    const rootClassName = customRootClass || s.swiperRoot;
    const instance = instanceID || `product_swiper_${slugify(context)}`;
    const ConnectedProductCard = connectShopify(ProductCard);
    return (
        <div className={rootClassName} data-type="product shopify">
            <div className={s.contextContainer}>
                <div className={s.context}>{context}</div>
                {edges.length > 3 && (
                    <div className={s.iconContainer}>
                        <div
                            className={`swiper-button swiper-icon-prev-${instance}`}
                        >
                            <Left />
                        </div>
                        <div
                            className={`swiper-button swiper-icon-next-${instance}`}
                        >
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                slidePerViewInMobile={1.99}
                slidePerViewInDesktop={4.35}
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                swiperIconPrevClassName={`swiper-icon-prev-${instance}`}
                swiperIconNextClassName={`swiper-icon-next-${instance}`}
                className={instance}
                key={instance}
                data-testid={instance}
            >
                {edges.map((edge, index: number) => {
                    const { node } = edge;
                    return (
                        <SwiperSlide
                            key={`sku-swiper-slide-${index}`}
                            data-product-id={trimGID(node.id)}
                            data-product-handle={node.handle}
                        >
                            <ConnectedProductCard
                                product={node}
                                photoVariant={'flatlay'}
                                position={index}
                                clientSettings={clientSettings}
                                imageGetter={imageGetter}
                            />
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperProductShopifyVariant;
