'use client';

import { SwiperItem, SwiperProps } from './type';
import { StaticImage, Video } from 'mmds';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';
import cn from 'clsx';
import s from './Swiper.module.css';

interface RenderSwiperProps {
    direction: 'vertical' | 'horizontal';
    cssMode: boolean;
    freeMode?: boolean;
    items: SwiperItem[];
}

const RenderSwiper = ({
    direction,
    cssMode,
    freeMode,
    items,
}: RenderSwiperProps) => {
    ('use client');

    return (
        <>
            <Swiper
                direction={direction}
                modules={[Scrollbar, Mousewheel, FreeMode]}
                className={cn(s.pdpGallery, 'swiper-pdp-gallery')}
                scrollbar={true}
                mousewheel={{
                    forceToAxis: false,
                    releaseOnEdges: true,
                    // On macbook trackpad, a swipe gesture was often causing a scroll of two slides (i.e. skipping a slide).
                    // Adjusting the threshold delta and threshold time fixes that.
                    // I think I've dialed it in okay but adjust the values as necessary.
                    thresholdDelta: 20,
                    thresholdTime: 75,
                }}
                slidesPerView="auto"
                spaceBetween="1px"
                cssMode={cssMode}
                freeMode={freeMode}
            >
                {items.map((item, index) => {
                    if (item.productVideo) {
                        return item.productVideo.map((video, videoIndex) => (
                            <SwiperSlide key={`${index}-${videoIndex}`}>
                                <Video
                                    desktopVideoUrl={video.desktopVideoUrl}
                                    mobileVideoUrl={video.mobileVideoUrl}
                                    controls={video.controls}
                                    autoplay={video.autoplay}
                                    muted={video.muted}
                                    loop={video.loop}
                                    poster={item.poster}
                                />
                            </SwiperSlide>
                        ));
                    } else if (item.productImage) {
                        return item.productImage.map((image, imageIndex) => (
                            <SwiperSlide key={`${index}-${imageIndex}`}>
                                <StaticImage
                                    desktopImage={image.desktopImage}
                                    mobileImage={image.mobileImage}
                                    fullWidth={true}
                                    altDescription={image.altText}
                                />
                            </SwiperSlide>
                        ));
                    }
                })}
            </Swiper>
        </>
    );
};

const SwiperPDPGalleryVariant = ({ block }: SwiperProps) => {
    const { items } = block;
    const { width, height } = items[0]?.productImage?.[0] || {};

    const firstItemAspectRatio =
        width && height ? `${width}/${height}` : '4 / 5';

    return (
        <>
            {/* Mobile Variant */}
            <div
                className={cn(s.PDPGalleryMobile, 'hidden-on-desktop')}
                style={{ aspectRatio: firstItemAspectRatio }}
            >
                <RenderSwiper
                    direction="horizontal"
                    items={items}
                    // enable css mode to prevent known library bug
                    // https://github.com/nolimits4web/swiper/issues/5743
                    cssMode={true}
                />
            </div>

            {/* Desktop Variant */}
            <div
                className={cn(s.PDPGalleryDesktop, 'hidden-on-mobile')}
                style={{ aspectRatio: firstItemAspectRatio }}
            >
                <RenderSwiper
                    direction="vertical"
                    items={items}
                    cssMode={false}
                    freeMode={true}
                />
            </div>
        </>
    );
};

export default SwiperPDPGalleryVariant;
