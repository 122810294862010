'use client';

import React from 'react';
import s from '@app/components/swiper/Swiper.module.css';
import { Left, Right, slugify } from 'mmds';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { ProductCardISO } from '@app/components/swiper/type';
import { SwiperSlide } from 'swiper/react';
import ProductCardIso from '@app/components/product-card-iso/product-card-iso';

const SwiperProductCardISOVariant = ({ block, context }) => {
    const itemsCheckForArrowVisibility = block.productCardISO.length > 3;
    const instance = `product_card_iso_swiper_${slugify(block.context)}`;

    return (
        <div className={s.swiperRoot}>
            <div className={s.contextContainer}>
                <div className={s.context}>{block.context}</div>
                {itemsCheckForArrowVisibility && (
                    <div className={s.iconContainer}>
                        <div
                            className={`swiper-button swiper-icon-prev-${instance}`}
                        >
                            <Left />
                        </div>
                        <div
                            className={`swiper-button swiper-icon-next-${instance}`}
                        >
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                slidePerViewInMobile={1.99}
                slidePerViewInDesktop={4.32}
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                swiperIconPrevClassName={`swiper-icon-prev-${instance}`}
                swiperIconNextClassName={`swiper-icon-next-${instance}`}
                className={instance}
            >
                {block.productCardISO.map(
                    (item: ProductCardISO, index: number) => {
                        return (
                            <SwiperSlide
                                key={`product-card-iso-swiper-slide-${index}`}
                            >
                                <ProductCardIso
                                    badge={item.badge}
                                    title={item.title}
                                    image={item.image}
                                    price={item.price}
                                    link={item.link}
                                    lang={context.lang}
                                />
                            </SwiperSlide>
                        );
                    }
                )}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperProductCardISOVariant;
