'use client';
import React from 'react';
import ChallengeOrEventForm from '@components/ChallengeOrEventForm';
import { PatternPosterData } from 'mmds';
import { Language } from '@app/lib/store-context/Context';
import { SliceZoneContext } from '@app/lib/prismic/types/slice-zone-context';
import {
    EventFormSliceChallengePrimary,
    EventFormSliceEventPrimary,
    EventFormSliceLegacyPrimary,
    Simplify,
} from 'prismicio-types';
import { PrismicRichText } from '@prismicio/react';
import cn from 'clsx';
import s from './index.module.css';
export type EventFormProps =
    | any
    | Simplify<EventFormSliceLegacyPrimary>
    | Simplify<EventFormSliceEventPrimary>
    | Simplify<EventFormSliceChallengePrimary>;
const EventForm = ({ slice, context }: EventFormProps): JSX.Element => {
    const contentRichText = {
        list: ({ children }) => <ul className={s.contentUL}>{children}</ul>,
    };
    let posterStyle: any = null;
    if (slice.primary.poster_bg_image.url) {
        posterStyle = {
            backgroundImage: `url(${slice.primary.poster_bg_image.url})`,
            backgroundSize: 'auto',
            backgroundColor: slice.primary.poster_bg_color || 'auto',
        };
    }
    const theme = slice.primary.theme || 'light';
    if (slice.variation === 'legacy') {
        return (
            <div
                className={cn(s.root, {
                    [s.withBGImage]: Boolean(slice.primary.poster_bg_image.url),
                })}
            >
                <div
                    className={cn(s.column, s.columnLeft)}
                    style={{
                        backgroundColor:
                            slice.primary.poster_bg_colour || 'auto',
                    }}
                    data-theme={`mobile-${theme.toLowerCase()} desktop-${theme.toLowerCase()}`}
                >
                    <div className={s.poster} style={posterStyle}>
                        {slice.primary.poster_bg_image.url && (
                            <img
                                src={slice.primary.poster_bg_image.url}
                                alt="Event Poster"
                                className={s.posterBGImage}
                            />
                        )}
                        <div className={s.posterContent}>
                            <PatternPosterData
                                data-testid="challenge_success_image"
                                content={
                                    <PrismicRichText
                                        field={slice.primary.content}
                                        components={contentRichText}
                                    />
                                }
                                productTitle={slice?.primary?.title}
                                lang={
                                    (context as SliceZoneContext)
                                        .lang as Language
                                }
                                variant="feature"
                                titlePosition="bottom"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        s.column,
                        s.columnRight,
                        s.challengeMessageZone,
                        s.section
                    )}
                    style={{
                        backgroundColor: slice.primary.form_bg_colour || 'auto',
                    }}
                >
                    <ChallengeOrEventForm
                        formAttributes={slice.primary}
                        formItems={slice.items}
                    />
                </div>
            </div>
        );
    }
    return (
        <ChallengeOrEventForm
            formAttributes={slice.primary}
            formItems={slice.items}
        />
    );
};

export default EventForm;
