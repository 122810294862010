// 'Dark' and 'Light' are the options in Prismic Slices
// 'Light Text' and 'Dark Text' are the strings MAAP are currently using in Shopify

export type ThemeSource = 'Dark' | 'Light' | 'Light Text' | 'Dark Text';
export interface ThemeConfig {
    mobileTheme?: ThemeSource; // Prismic
    desktopTheme?: ThemeSource; // Prismic
    componentTheme?: ThemeSource; // Shopify
}

export const generateTheme = (config: ThemeConfig): string => {
    let mobileTheme = 'light';
    let desktopTheme = 'light';

    // Determine mobileTheme
    if (
        config.mobileTheme === 'Dark' ||
        config.componentTheme === 'Light Text'
    ) {
        mobileTheme = 'dark';
    } else if (
        config.mobileTheme === 'Light' ||
        config.componentTheme === 'Dark Text'
    ) {
        mobileTheme = 'light';
    }

    // Determine desktopTheme
    if (
        config.desktopTheme === 'Dark' ||
        config.componentTheme === 'Light Text'
    ) {
        desktopTheme = 'dark';
    } else if (
        config.desktopTheme === 'Light' ||
        config.componentTheme === 'Dark Text'
    ) {
        desktopTheme = 'light';
    }

    return `mobile-${mobileTheme} desktop-${desktopTheme}`;
};
