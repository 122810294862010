'use client';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { PatternPosterData, StaticImage } from 'mmds';
import { useScript } from '@app/scripts';
import s from './NostoDistruptorPoster.module.css';
import { generateTheme } from '@app/lib/helpers/generate-theme';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';

/**
 * Props for `NostoPosterPlacement`.
 */
export type NostoPosterPlacementProps =
    SliceComponentProps<Content.NostoPosterPlacementSlice>;

/**
 * Component for "NostoPosterPlacement" Slices.
 */
const NostoPosterPlacement = ({
    slice,
    index,
}: NostoPosterPlacementProps): JSX.Element | null => {
    const { lang, collection } = useParams();
    const { nostoInitialised } = useScript();
    const [posterContent, setPosterContent] = useState<any>(false);
    const placementIdentifier = slice?.primary.placement_id as string;

    useLayoutEffect(() => {
        try {
            if (!(window as any).nostojs) return;
            (window as any)?.nostojs((api) => {
                const session = api.defaultSession();
                session
                    .viewOther(session)
                    .setPlacements([placementIdentifier])
                    .load()
                    .then((data) => {
                        const content =
                            data.recommendations[placementIdentifier];
                        const json = JSON.parse(content.html);
                        if (!json) return;
                        setPosterContent(json);
                    });
            });
        } catch (e) {
            console.log('Error fetching or parsing Nosto content', e);
        }
    }, [nostoInitialised, collection, lang]);

    if (!posterContent) return null;

    const {
        title,
        content,
        mobileTheme,
        desktopTheme,
        desktopImage,
        mobileImage,
        backgroundColor,
        cta,
        ctaLink,
    } = posterContent;

    const theme = generateTheme({
        mobileTheme,
        desktopTheme,
    });

    return (
        <div data-theme={theme} className={s.root} key={index}>
            <div
                className={s.posterFeatureBG}
                style={{
                    backgroundColor: backgroundColor || '#E7E7E7',
                }}
            >
                <StaticImage
                    desktopImage={desktopImage}
                    mobileImage={mobileImage}
                    altDescription={title}
                    fullWidth={true}
                    urlTransformer={proxyImageUrlIfEnabled}
                />
                <PatternPosterData
                    variant="feature"
                    title={title}
                    content={content}
                    ctaSet={[
                        {
                            label: cta,
                            hrefLink: ctaLink,
                        },
                    ]}
                    lang={lang as string}
                />
            </div>
        </div>
    );
};

export default NostoPosterPlacement;
