'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { CTASet, PatternCTASet, PatternTitleSubtitle, StaticImage } from 'mmds';
import { useScript } from '@app/scripts';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import { generateTheme } from '@app/lib/helpers/generate-theme';
import s from '@app/slices/NostoPoster/NostoPoster.module.css';
import cn from 'clsx';

/**
 * Props for `NostoPoster`.
 */
export type NostoPosterProps = SliceComponentProps<Content.NostoPosterSlice>;

/**
 * Component for "NostoPoster" Slices.
 */
const NostoPoster = ({ slice }: NostoPosterProps): JSX.Element | null => {
    const { lang, collection } = useParams();
    const { nostoInitialised } = useScript();
    const [billboardStaticContent, setBillboardStaticContent] =
        useState<any>(false);
    const placementIdentifier = slice?.primary.placement_id as string;

    useLayoutEffect(() => {
        try {
            if (!(window as any).nostojs) return;
            (window as any)?.nostojs((api) => {
                const session = api.defaultSession();
                session
                    .viewOther(session)
                    .setPlacements([placementIdentifier])
                    .load()
                    .then((data) => {
                        const content =
                            data.recommendations[placementIdentifier];
                        const json = JSON.parse(content.html);
                        if (!json) return;
                        setBillboardStaticContent(json);
                    });
            });
        } catch (e) {
            console.log('Error fetching or parsing Nosto content', e);
        }
    }, [nostoInitialised, collection, lang]);

    if (!billboardStaticContent) return null;

    const {
        title,
        content,
        mobileTheme,
        desktopTheme,
        desktopImage,
        mobileImage,
        backgroundColor,
        cta1Label,
        cta1Link,
        cta1Variant,
        cta2Label,
        cta2Link,
        cta2Variant,
        cta3Label,
        cta3Link,
        cta3Variant,
        contentPosition,
    } = billboardStaticContent;

    const theme = generateTheme({
        mobileTheme,
        desktopTheme,
    });

    const ctaSet: CTASet = [
        {
            label: cta1Label,
            hrefLink: cta1Link,
            variant: cta1Variant,
        },
        {
            label: cta2Label,
            hrefLink: cta2Link,
            variant: cta2Variant,
        },
        {
            label: cta3Label,
            hrefLink: cta3Link,
            variant: cta3Variant,
        },
    ];

    const contentPositionWithDefault = contentPosition || 'bottomLeft';

    const contentLayoutClassname = cn({
        [s.bottomLeft]: contentPositionWithDefault === 'bottomLeft',
        [s.center]: contentPosition === 'center',
        [s.topLeft]: contentPosition === 'topLeft',
    });
    const buttonContentLayout = cn(s.buttonRoot, {
        [s.buttonBottomLeft]: contentPositionWithDefault === 'bottomLeft',
        [s.buttonCenter]: contentPosition === 'center',
        [s.buttonTopLeft]: contentPosition === 'topLeft',
    });
    const identifier = `bb_static_poster`;

    return (
        <div
            key={identifier}
            data-testid={identifier}
            className={cn(s.billboardStaticRoot)}
            data-theme={theme}
        >
            <StaticImage
                desktopImage={desktopImage}
                mobileImage={mobileImage}
                altDescription={title}
                fullWidth={true}
                urlTransformer={proxyImageUrlIfEnabled}
            />
            <div className={`${s.overlayContent} ${contentLayoutClassname}`}>
                <div className={`${s.content} ${contentLayoutClassname}`}>
                    <PatternTitleSubtitle
                        title={title}
                        titleSize="large"
                        subTitle={content}
                        subTitleSize="medium"
                        billboardType="static"
                    />
                    <div className={buttonContentLayout}>
                        {ctaSet && (
                            <PatternCTASet set={ctaSet} lang={lang as string} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NostoPoster;
