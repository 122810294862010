import getFeatureFlag from '@app/lib/features/get-feature-flag';

function proxyImageUrlIfEnabled(imageUrl: string) {
    if (!getFeatureFlag('imageProxy')) {
        return imageUrl;
    }

    if (!process.env.NEXT_PUBLIC_IMAGE_PROXY_BASE_URL) {
        return imageUrl;
    }

    return imageUrl.replace(
        'images.prismic.io',
        process.env.NEXT_PUBLIC_IMAGE_PROXY_BASE_URL
    );
}

export default proxyImageUrlIfEnabled;
