import { SwiperItem, SwiperProps } from './type';
import { Language } from '@app/lib/store-context/Context';
import SwiperProductSKUFeatureVariant from '../../components/swiper/SwiperProductSKUFeature.variant';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import fetchProductsByHandles from '@app/lib/shopify/methods/fetch-products-by-handles';

export function imageGetterSKU(imageSRCs: string[]): (string | undefined)[] {
    const mainImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_CLP_SKU_Lifestyle_01')
    );
    const mainImageFallback = imageSRCs.find((url: string | string[]) =>
        url.includes('_LP_FLATLAY')
    );

    const hoverImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_CLP_SKU_Lifestyle_02')
    );
    const fallbackHoverImage = imageSRCs.find((url: string | string[]) =>
        url.includes('PRODUCT_CARD_ALT')
    );

    return [mainImage || mainImageFallback, hoverImage || fallbackHoverImage];
}

const SwiperProductSKUFeatureVariantServer = async ({
    block,
    context,
}: SwiperProps) => {
    const { items } = block;
    const productHandles = items
        .map((item: SwiperItem) => item?.sku || null)
        .filter(Boolean) as string[];

    if (productHandles.length === 0) {
        console.log('No product handles provided.');
        return null;
    }

    const clientSettings = getShopifyClientSettings(context.lang as Language);

    try {
        const products = await fetchProductsByHandles(
            clientSettings,
            productHandles
        );

        if (!products) {
            return [];
        }

        if (!products || products.length === 0) {
            throw new Error('Failed to map products or no products found');
        }

        return (
            <SwiperProductSKUFeatureVariant
                products={products}
                block={block}
                context={context}
            />
        );
    } catch (error) {
        console.error('Error fetching SKU feature products:', error);
        return null;
    }
};

export default SwiperProductSKUFeatureVariantServer;
