import { StaticImage } from 'mmds';
import s from './product-card-iso.module.css';
import Linker from '@app/components/linker/linker';

const ProductCardISO = ({ title, link, badge, image, price, lang }) => {
    return (
        <Linker passthrough={!link} href={link} lang={lang} title={title}>
            <div className={s.productCardISO}>
                <div className={s.productCardISOContainer}>
                    <div className={s.badge}>{badge ? badge : 'In-Store Only'}</div>
                    <StaticImage desktopImage={image} mobileImage={image} altDescription={title} />
                </div>
                <div className={s.summary}>
                    <div>{title}</div>
                    <div>{price}</div>
                </div>
            </div>
        </Linker>
    )
}

export default ProductCardISO;
