'use client';

import { ProductSkuFeatureSwiperProps } from './type';
import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Left, ProductCard, Right, slugify } from 'mmds';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import connectShopify from '@app/components/product-card/connect-shopify';
import { imageGetterSKU } from '@app/components/swiper/SwiperProductSKUFeature.variant.server';
import cn from 'clsx';
import s from './Swiper.module.css';

const SwiperProductSKUFeatureVariant = ({
    block,
    products,
    context,
}: ProductSkuFeatureSwiperProps) => {
    const { context: contextText, title } = block;
    if (!products) return null;
    const ConnectedProductCard = connectShopify(ProductCard);
    const clientSettings = getShopifyClientSettings(context.lang as Language);
    return (
        <div className={s.swiperRoot} data-type="product sku feature">
            <div className={s.contextContainer}>
                <div className={s.context}>{contextText}</div>
                {products?.length > 3 && (
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-8">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-8">
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            {title && <div className={s.productFlatLayTitle}>{title}</div>}
            <div
                className={cn({
                    productSKUFor3: products.length === 3,
                    productSKUFor2:
                        products.length === 2 || products.length === 1,
                })}
            >
                <div className={s.productSKUDesktop}>
                    {products?.map((product, index) => {
                        return (
                            <SwiperSlide
                                className={s.productSKUCard}
                                key={index}
                            >
                                <ConnectedProductCard
                                    product={product}
                                    position={index}
                                    clientSettings={clientSettings}
                                    imageGetter={imageGetterSKU}
                                />
                            </SwiperSlide>
                        );
                    })}
                </div>
                {products?.length === 1 && (
                    <div
                        className={cn(
                            s.productSKUMobile,
                            s.productSKUSingleImage
                        )}
                    >
                        {products?.map((product, index) => {
                            const identifier = `swiper_product_SKU_feature_variant_${slugify(
                                contextText
                            )}`;
                            return (
                                <div key={index} data-testid={identifier}>
                                    <ConnectedProductCard
                                        product={product}
                                        position={index}
                                        clientSettings={clientSettings}
                                        imageGetter={imageGetterSKU}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            {/* Mobile Swiper */}
            {products?.length >= 2 && (
                <div className={s.productSKUMobile}>
                    <ScrollBarSwiper
                        className="swiper8"
                        slidePerViewInMobile={1.3}
                        spaceBetweenMobile={24}
                        spaceBetweenDesktop={12}
                        swiperIconNextClassName="swiper-icon-next-8"
                        swiperIconPrevClassName="swiper-icon-prev-8"
                    >
                        {products?.map((product, index) => {
                            const identifier = `swiper_product_SKU_feature_variant_${slugify(
                                contextText
                            )}`;
                            return (
                                <SwiperSlide
                                    key={index}
                                    data-testid={identifier}
                                >
                                    <ConnectedProductCard
                                        product={product}
                                        position={index}
                                        clientSettings={clientSettings}
                                        imageGetter={imageGetterSKU}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </ScrollBarSwiper>
                </div>
            )}

            {products?.length > 3 && (
                <ScrollBarSwiper
                    key="swiper_product_SKU_feature_variant"
                    className="swiper8"
                    slidePerViewInMobile={1.86}
                    slidePerViewInDesktop={4.2}
                    swiperIconNextClassName="swiper-icon-next-8"
                    swiperIconPrevClassName="swiper-icon-prev-8"
                >
                    {products?.map((product, index) => {
                        const identifier = `swiper_product_SKU_feature_variant_${slugify(
                            contextText
                        )}`;
                        return (
                            <SwiperSlide key={index} data-testid={identifier}>
                                <ConnectedProductCard
                                    product={product}
                                    position={index}
                                    clientSettings={clientSettings}
                                    imageGetter={imageGetterSKU}
                                />
                            </SwiperSlide>
                        );
                    })}
                </ScrollBarSwiper>
            )}
        </div>
    );
};

export default SwiperProductSKUFeatureVariant;
