'use client';

import React, { useState } from 'react';
import {
    StaticImageItem,
    SwiperItem,
    SwiperProductFlatlayTabbedCollectionsVariantProps,
} from './type';
import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Button, CTAItem, Left, Right, slugify, StaticImage } from 'mmds';
import Linker from '@app/components/linker/linker';
import localisePath from '@app/lib/helpers/localise-path';
import s from './Swiper.module.css';

const SwiperProductFlatlayTabbedCollectionsVariant = ({
    block,
    collections,
    context,
}: SwiperProductFlatlayTabbedCollectionsVariantProps) => {
    const { items, context: contextText } = block;
    const [activeItem, setActiveItem] = useState(items[0]);
    const selectedCollection: { handle: string; products: StaticImageItem[] } =
        collections.find((collection) => {
            return collection.rawHandle === activeItem.collectionHandle;
        });

    return (
        <div
            style={{ backgroundColor: activeItem.backgroundColor }}
            className={s.swiperRoot}
        >
            <div className={s.contextContainer}>
                <div className={s.context}>
                    {contextText}
                    <span className={s.divider}>:</span>
                    <div className={s.productFlatLayCollectionHandle}>
                        {items?.map((item: SwiperItem, key) => {
                            const collection = collections.find(
                                (collection) =>
                                    collection.handle === item.collectionHandle
                            );
                            return (
                                <Button
                                    key={`${item.collectionHandle}_${key}`}
                                    onClick={() => setActiveItem(item)}
                                    className={s.productFlatLayCollectionButton}
                                    variant={
                                        activeItem.tabTitle === item.tabTitle
                                            ? 'text'
                                            : 'quite'
                                    }
                                    textVariantPadding={false}
                                >
                                    {item.tabTitle
                                        ? item.tabTitle
                                        : collection.title}
                                </Button>
                            );
                        })}
                    </div>
                </div>
                {selectedCollection?.products.length > 3 && (
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-7">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-7">
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                className="swiper7"
                slidePerViewInMobile={2.9}
                slidePerViewInDesktop={9.4}
                spaceBetweenDesktop={0}
                spaceBetweenMobile={0}
                swiperIconNextClassName="swiper-icon-next-7"
                swiperIconPrevClassName="swiper-icon-prev-7"
            >
                {selectedCollection?.products?.map(
                    (item: StaticImageItem, index: number) => {
                        const identifier = `swiper_product_flat_lay_tabbed_variant_${slugify(
                            contextText
                        )}`;
                        return (
                            <SwiperSlide key={index} data-testid={identifier}>
                                <div
                                    className={
                                        activeItem.backgroundColor
                                            ? ''
                                            : s.image
                                    }
                                >
                                    <Linker
                                        href={item.link}
                                        title={`Go to the product page: ${item.title}`}
                                        lang={context.lang}
                                    >
                                        <StaticImage
                                            desktopImage={item.desktopImage}
                                            mobileImage={item.mobileImage}
                                            altDescription={item.title}
                                            fullWidth={true}
                                        />
                                    </Linker>
                                </div>
                            </SwiperSlide>
                        );
                    }
                )}
            </ScrollBarSwiper>
            <div className={s.productFlatLayContent}>
                {activeItem.description && (
                    <div className={s.productFlatLayDescription}>
                        {activeItem.description}
                    </div>
                )}
                <div className={s.productFlatLayButtons}>
                    {activeItem.CTASet?.map((item: CTAItem, index) => {
                        return (
                            item.link && (
                                <Button
                                    variant={item.variant}
                                    label={item.label}
                                    hrefLink={localisePath(
                                        item.link,
                                        context.lang
                                    )}
                                    icon={item.icon}
                                    key={index}
                                    lang={context.lang}
                                />
                            )
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SwiperProductFlatlayTabbedCollectionsVariant;
