'use client';

import { useScript } from '@app/scripts';
import NostoRecommendationsBlock from '@app/components/nosto/nosto-recommendations-block';
import { SwiperProps } from '@app/components/swiper/type';

const SwiperProductNosto = ({ block, context }: SwiperProps) => {
    const { placementId } = block;
    const { nostoInitialised } = useScript();
    if (!nostoInitialised || !placementId) return null;

    return (
        <>
            <NostoRecommendationsBlock
                placementIdentifier={placementId}
                pageKey={`landing_page_${placementId}`}
                id={placementId}
                type="landing_page"
            />
        </>
    );
};

export default SwiperProductNosto;
