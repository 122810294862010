'use client';

import {
    StaticImageItem,
    SwiperProductFlatlaySingleCollectionVariantProps,
} from './type';
import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Button, CTAItem, Left, Right, slugify, StaticImage } from 'mmds';
import localisePath from '@app/lib/helpers/localise-path';
import Linker from '@app/components/linker/linker';
import s from './Swiper.module.css';

const SwiperProductFlatlayCollection = ({
    block,
    products,
    context,
}: SwiperProductFlatlaySingleCollectionVariantProps) => {
    const {
        context: contextText,
        title,
        description,
        CTASet,
        backgroundColor,
    } = block;
    const productLength = products?.length || 0;

    return (
        <div
            style={{ backgroundColor: backgroundColor }}
            className={s.swiperRoot}
        >
            <div className={s.contextContainer}>
                <div className={s.context}>{contextText}</div>
                {productLength > 3 && (
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-6">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-6">
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            {title && <div className={s.productFlatLayTitle}>{title}</div>}
            <ScrollBarSwiper
                className="swiper6"
                slidePerViewInMobile={2.9}
                slidePerViewInDesktop={9.4}
                spaceBetweenDesktop={0}
                spaceBetweenMobile={0}
                swiperIconNextClassName="swiper-icon-next-6"
                swiperIconPrevClassName="swiper-icon-prev-6"
            >
                {products?.map((item: StaticImageItem, index: number) => {
                    const identifier = `swiper_product_flat_lay_title_variant_${slugify(
                        contextText
                    )}`;
                    return (
                        <SwiperSlide key={index} data-testid={identifier}>
                            <div className={backgroundColor ? '' : s.image}>
                                <Linker
                                    href={item.link}
                                    title={`Go to the product page: ${item.title}`}
                                    lang={context.lang}
                                >
                                    <StaticImage
                                        desktopImage={item.desktopImage}
                                        mobileImage={item.mobileImage}
                                        altDescription={item.title}
                                        fullWidth={true}
                                    />
                                </Linker>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
            <div className={s.productFlatLayContent}>
                {description && (
                    <div className={s.productFlatLayDescription}>
                        {description}
                    </div>
                )}
                {CTASet?.[0]?.label && (
                    <div className={s.productFlatLayButtons}>
                        {CTASet?.map((item: CTAItem, index) => {
                            return (
                                item.link && (
                                    <Button
                                        variant={item.variant}
                                        label={item.label}
                                        hrefLink={localisePath(
                                            item.link,
                                            context.lang
                                        )}
                                        icon={item.icon}
                                        key={index}
                                        lang={context.lang}
                                    />
                                )
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SwiperProductFlatlayCollection;
