'use client';

import React, { useLayoutEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { LookbookOverlayProps } from '../lookbook/type';
import ScrollBarSwiper from '../../../app/components/scrollbar-swiper';
import { Left, Right, slugify, StaticImage, ToggleButton } from 'mmds';
import LookbookDrawer from './LookbookDrawer';
import s from './Lookbook.module.css';
import { useSelectedProductStore } from './state';
import { BuyContextProvider } from '@app/[lang]/products/context';
import ProductNotifyDrawer from '@app/[lang]/products/product-notify-drawer/product-notify-drawer';
import { useSearchParams } from 'next/navigation';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import { isChrome, isEdge } from 'react-device-detect';

const Lookbook = ({
    block,
    context: PrismicSliceContext,
}: LookbookOverlayProps) => {
    const searchParams = useSearchParams();
    const anchorRef = useRef<HTMLDivElement>(null)
    const urlParamContext: string = (
        searchParams.get('lookbook') as string
    )?.toLowerCase();

    const [lookbookContext, setLookbookContext] = useState(
        urlParamContext ? urlParamContext : 'woman'
    );
    const { context, items , contextSwitcher } = block;
    const { selectedProduct } = useSelectedProductStore();
    const itemsCheckForArrowVisibility = items?.length > 3;
    const hash = window?.location?.hash

    const handleToggle = (newContext: string) => {
        setLookbookContext(newContext);
    };

    const getInitialValue = (value: string) => {
        //if prismic defaulting fails set to 'Woman'
        return value == null ? 'woman' : value.toLowerCase();
    };

    useLayoutEffect(() => {
        if (hash === '#lookbook' && anchorRef.current){
            if (isEdge || isChrome){
                const rect = anchorRef.current.getBoundingClientRect()
                const offsetTop = rect.top + document.body.scrollTop
                window.scrollTo({top: offsetTop, behavior: 'smooth'})
            } else {
                anchorRef.current.scrollIntoView({behavior: 'smooth'})
            }
        }
    },[hash]);

    if (!items.length) return null;

    const defaultContextSwitcher = contextSwitcher === 'Disabled' ? contextSwitcher : 'Enabled';

    return (
        <BuyContextProvider product={selectedProduct!} inDrawer>
            <div ref={anchorRef} className={s.swiperRoot}>
                <div className={s.contextContainer}>
                    <div className={s.context}>
                        {context}
                        {defaultContextSwitcher === 'Enabled' && <ToggleButton
                            toggleContext={lookbookContext}
                            handleContext1={() => handleToggle('woman')}
                            handleContext2={() => handleToggle('man')}
                            context1={'Woman'}
                            context2={'Man'}
                        />}
                    </div>
                    {itemsCheckForArrowVisibility && (
                        <div className={s.iconContainer}>
                            <div className="swiper-button swiper-icon-prev-10">
                                <Left />
                            </div>
                            <div className="swiper-button swiper-icon-next-10">
                                <Right />
                            </div>
                        </div>
                    )}
                </div>
                <ScrollBarSwiper
                    className="swiper2"
                    swiperIconNextClassName="swiper-icon-next-10"
                    swiperIconPrevClassName="swiper-icon-prev-10"
                    spaceBetweenDesktop={12}
                    spaceBetweenMobile={8}
                    slidePerViewInMobile={1.2}
                    slidePerViewInDesktop={3.1}
                >
                    {items.map((item, index) => (
                        <div key={index} className="lookbook">
                            {item?.lookbook?.map((look, lookIndex) => {
                                const contextValue = getInitialValue(
                                    look.lookbookContext.toLowerCase()
                                );
                                if (contextValue === lookbookContext) {
                                    const identifier = `lookbook_swiper_${slugify(
                                        look.heading
                                    )}_${look.heading}`;

                                    // handles variable required here cuz it loops through the look and
                                    // renders man/woman handles depends on the toggle.

                                    const handles = [
                                        look.productHandle1,
                                        look.productHandle2,
                                        look.productHandle3,
                                        look.productHandle4,
                                    ].filter(Boolean);

                                    return (
                                        <SwiperSlide
                                            key={`${look.heading}-${lookIndex}`}
                                            data-textid={identifier}
                                        >
                                            <LookbookDrawer
                                                look={look}
                                                handles={handles}
                                            >
                                                <StaticImage
                                                    desktopImage={
                                                        look.desktopImage
                                                    }
                                                    mobileImage={
                                                        look.mobileImage
                                                    }
                                                    desktopHeight="100%"
                                                    desktopWidth="1200px"
                                                    altDescription={
                                                        look.heading
                                                    }
                                                    urlTransformer={
                                                        proxyImageUrlIfEnabled
                                                    }
                                                />
                                            </LookbookDrawer>
                                            <div
                                                className={
                                                    s.lookBookDescription
                                                }
                                            >
                                                <div>{look.heading}</div>
                                                <div>
                                                    {look.productTitle1}{' '}
                                                    <span
                                                        className={
                                                            s.variantTitle
                                                        }
                                                    >
                                                        {look.variantTitle1}
                                                    </span>
                                                </div>
                                                <div>
                                                    {look.productTitle2}{' '}
                                                    <span
                                                        className={
                                                            s.variantTitle
                                                        }
                                                    >
                                                        {look.variantTitle2}
                                                    </span>
                                                </div>
                                                <div>
                                                    {look.productTitle3}{' '}
                                                    <span
                                                        className={
                                                            s.variantTitle
                                                        }
                                                    >
                                                        {look.variantTitle3}
                                                    </span>
                                                </div>
                                                <div>
                                                    {look.productTitle4}{' '}
                                                    <span
                                                        className={
                                                            s.variantTitle
                                                        }
                                                    >
                                                        {look.variantTitle4}
                                                    </span>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    ))}
                </ScrollBarSwiper>
            </div>
            <ProductNotifyDrawer />
        </BuyContextProvider>
    );
};

export default Lookbook;
