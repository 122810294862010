"use client";

import cn from 'clsx';
import React from 'react';
import { Button, StaticImage } from 'mmds';
import LookbookDrawer from '@app/components/lookbook';
import { BuyContextProvider } from '@app/[lang]/products/context';
import s from '@app/components/lookbook-alt/LookbookAlt.module.css';
import { LookbookAltProps } from '@app/components/lookbook-alt/types';
import { useSelectedProductStore } from '@app/components/lookbook/state';
import ProductNotifyDrawer from '@app/[lang]/products/product-notify-drawer';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import { generateTheme } from '@app/lib/helpers/generate-theme';

const LookbookAlt = ({ block }: LookbookAltProps) => {
    const {
        context,
        heading,
        desktopImage,
        mobileImage,
        secondaryDesktopImage,
        secondaryMobileImage,
        productHandle1,
        productHandle2,
        productHandle3,
        productHandle4,
        productHandle5,
        mobileTheme,
        desktopTheme,
        backgroundColour
    } = block;

    const handles = [
        productHandle1,
        productHandle2,
        productHandle3,
        productHandle4,
        productHandle5
    ].filter(Boolean);

    const { selectedProduct } = useSelectedProductStore();

    const theme = generateTheme({
        mobileTheme,
        desktopTheme,
    });

    return (
        <div data-theme={theme} style={{ backgroundColor: backgroundColour || 'var(--surface-primary)' }}>
            <BuyContextProvider product={selectedProduct!} inDrawer>
                <div className={s.lookbookAlt}>
                    <LookbookDrawer heading={heading} handles={handles}>
                        {context && <div>
                            <Button variant="text" textVariantPadding={false}>
                                {context}{' '}<span className={cn(s.dot)} />
                            </Button>
                        </div>}
                        <div className={s.lookbook_alt_image}>
                            <div className={s.initial_image}>
                                <StaticImage
                                    desktopImage={desktopImage}
                                    mobileImage={mobileImage}
                                    desktopHeight="100%"
                                    desktopWidth="3000px"
                                    altDescription={heading}
                                    // urlTransformer={proxyImageUrlIfEnabled}
                                />
                            </div>
                            <div className={s.secondary_image}>
                                <StaticImage
                                    desktopImage={secondaryDesktopImage}
                                    mobileImage={secondaryMobileImage}
                                    desktopHeight="100%"
                                    desktopWidth="100%"
                                    altDescription={heading}
                                    // urlTransformer={proxyImageUrlIfEnabled}
                                />
                            </div>
                            {/*<div className={s.lookbook_alt_overlay} />*/}
                        </div>
                    </LookbookDrawer>
                </div>
                <ProductNotifyDrawer />
            </BuyContextProvider>
        </div>
    )
}

export default LookbookAlt;
